import { ChainId } from '@uniswap/sdk-core'
import { useWeb3React } from '@web3-react/core'
import BadgeBg from 'assets/images/badge-bg.png'
import BadgeDefault from 'assets/images/badge-default.png'
import BadgeMintedLogo from 'assets/images/badge-logo.png'
import Done from 'assets/images/done.png'
import NeedDone from 'assets/images/needDone.png'
import Loader from 'components/Icons/LoadingSpinner'
import { rgba } from 'polished'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { CloseIcon } from 'theme/components'

import BadgeABI from '../ScrollBadge/abis/CanvasBadge.json'
import { checkBadgeEligibility, checkIfHasBadgeByAddress, mintBadge } from '../ScrollBadge/canvasService'
const BadgeFloat = styled.div`
  position: fixed;
  bottom: 80px;
  right: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 100;
  width: 260px;
  @media (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 230px;
    right: 10px;
    bottom: 70px;
  }
`
const BadgeContent = styled.div`
  width: 260px;
  height: auto;
  padding: 22px 14px 22px 14px;
  background: url(${BadgeBg}) no-repeat;
  background-size: 100% 100%;
  position: relative;
  .close {
    width: 16px;
    position: absolute;
    top: 2px;
    right: 5px;
  }
  span {
    display: inline-block;
    font-size: 13px;
    line-height: 18px;
  }
  @media (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 230px;
    height: auto;
    padding: 20px 10px 22px 10px;
    span {
      font-size: 12px;
      line-height: 17px;
    }
  }
`
const TaskList = styled.div`
  width: 100%;
  padding: 4px 12px;
  font-size: 13px;
  background: #2b2b2b;
  margin-top: 8px;
  border-radius: 6px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  ::after {
    content: '';
    display: inline-block;
    width: 13px;
    height: 13px;
    background: url(${(props: any) => (props['data-done'] ? Done : NeedDone)});
    background-size: 100% 100%;
  }
`
const Btn = styled.div`
  width: 100%;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  margin: 10px 0 6px 0;
  cursor: ${(props: any) => (props['data-mint'] ? 'pointer' : 'default')};
  color: ${(props: any) => (props['data-mint'] ? '#fff' : rgba(255, 255, 255, 0.5))};
  background: ${(props: any) => (props['data-mint'] ? '#37B826' : '#2B2B2B')};
  font-weight: 600;
  font-family: PingFang SC;
`
const BadgeLogo = styled.div`
  width: 60px;
  height: 60px;
  margin-top: 6px;
  margin-right: 20px;
  background: url(${(props: any) => (props['data-minted'] ? BadgeMintedLogo : BadgeDefault)});
  background-size: 100% 100%;
  cursor: ${(props: any) => (props['data-cursor'] ? 'default' : 'pointer')};
  @media (max-width: ${({ theme }) => theme.breakpoint.md}px) {
    width: 40px;
    height: 40px;
    margin-top: 2px;
    margin-left: 32px;
  }
`
export default function BadgePage() {
  const { chainId, account, provider } = useWeb3React()
  //testnet
  let ZEBRA_BADGE_ADDRESS = '0x619a0A19203697f36C4092C757485734Ec23b2eB'
  let ZEBRA_ATTESTER_PROXY_ADDRESS = '0xD528308D3f0c6CfF15C6D25193d77aFB123ABe82'
  let Zebra_BASE_URL = 'https://zktrade.net/api/badge'
  //mainnet
  if (chainId == ChainId.SCROLL) {
    ZEBRA_BADGE_ADDRESS = '0x09E14E520eec3583681Fe225a4B506743EC3cc78'
    ZEBRA_ATTESTER_PROXY_ADDRESS = '0x35BcDcd5813a08f890163c166826856B47e7977F'
    Zebra_BASE_URL = 'https://zebra.xyz/api/badge'
  }
  const zebraBadge = {
    name: 'Zebra',
    badgeContract: ZEBRA_BADGE_ADDRESS,
    attesterProxy: ZEBRA_ATTESTER_PROXY_ADDRESS,
    description:
      "Users who participate in Scroll Canvas and complete tasks will receive Zebra's 'zebra' badge. Users with this badge will enjoy Zebra's early benefits in the future.",
    image: 'https://app.zebra.xyz/images/badge.png',
    issuer: {
      origin: 'https://zebra.xyz/',
      name: 'Zebra',
      logo: 'https://scroll-eco-list.netlify.app/logos/Zebra.png',
    },
    baseUrl: Zebra_BASE_URL,
    native: false,
  }
  const [isMintBadge, setIsMintBadge] = useState<boolean>(false)
  const [hasBadge, setHasBadge] = useState<boolean>(false)
  const [showBodgeInfo, setShowBodgeInfo] = useState<boolean>(true)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [swapStatus, setSwapStatus] = useState<boolean>(false)
  const [liqStatus, setLiqStatus] = useState<boolean>(false)
  useEffect(() => {
    console.log('account', account)

    const checkValid = async () => {
      const result = await checkBadgeEligibility(provider, account, zebraBadge)
      console.log('BADGE can get badge: ', result)
      const { eligibility, data } = result
      setIsMintBadge(eligibility)
      setSwapStatus(data?.swap || false)
      setLiqStatus(data?.liq || false)
    }
    const checkHasBadge = async () => {
      const hasBadge = await checkIfHasBadgeByAddress(provider, account, ZEBRA_BADGE_ADDRESS)
      console.log('BADGE has created: ', hasBadge)
      setHasBadge(hasBadge)
      if (!hasBadge) {
        checkValid()
      }
    }
    checkHasBadge()

    const intervalCheckValid = setInterval(() => checkValid(), 10000)
    return () => clearInterval(intervalCheckValid)
  }, [chainId, account])

  // close
  const close = () => {
    setShowBodgeInfo(false)
  }
  //用户创建zebra徽章
  const mintZebraBadge = async () => {
    if (!showLoading && isMintBadge) {
      setShowLoading(true)
      const uid = await mintBadge(provider, account, {
        badgeContract: ZEBRA_BADGE_ADDRESS,
        nftAddress: null,
        nftAbi: BadgeABI,
        attesterProxy: ZEBRA_ATTESTER_PROXY_ADDRESS,
        baseUrl: Zebra_BASE_URL,
      })
      setShowLoading(false)
      if (uid) {
        setIsMintBadge(false)
        setHasBadge(true)
      }
      console.log('BADGE created uid: ', uid)
    }
  }
  return (
    <BadgeFloat>
      {showBodgeInfo && (
        <BadgeContent>
          <CloseIcon className="close" onClick={close} />
          <span>
            {hasBadge
              ? `Congrats, you have completed the task and minted the Zebra badge; you can also continue to add liquidity in Zebra and earn more Scroll Marks`
              : `Hello there! By completing the following 2 tasks you can mint <Zebra badge>`}
          </span>
          {!hasBadge && (
            <>
              <TaskList data-done={swapStatus}>1、Swap Over $500</TaskList>
              <TaskList data-done={liqStatus}>2、Add liquidity Over $1000</TaskList>
            </>
          )}
          {hasBadge ? (
            <Btn>Minted</Btn>
          ) : (
            <Btn data-mint={isMintBadge && !showLoading} onClick={mintZebraBadge}>
              {showLoading ? <Loader stroke="#fff"></Loader> : 'Mint Badge'}
            </Btn>
          )}
        </BadgeContent>
      )}
      <BadgeLogo data-minted={hasBadge} data-cursor={showBodgeInfo} onClick={() => setShowBodgeInfo(true)}></BadgeLogo>
    </BadgeFloat>
  )
}
