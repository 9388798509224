// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { ethers } from 'ethers'

import AttestProxyABI from './abis/CanvasAttestProxy.json'
import BadgeABI from './abis/CanvasBadge.json'
import ProfileABI from './abis/CanvasProfile.json'
import ProfileRegistryABI from './abis/CanvasProfileRegistry.json'
import { checkDelegatedAttestation, decodeBadgePayload } from './utils'

const scrollRequest = (url: string, options?: object) => {
  return fetch(url, options)
    .then(async (res) => {
      if (res.ok) {
        return res.json()
      }
      // server response but not 200
      const message = await res.text()
      const error = new Error(message)
      // error.status = res.status
      throw error
    })
    .then((data) => data)
}
// window.scrollRequest = scrollRequest

const baseUrl = 'https://zktrade.net/api/badge'
const checkBadgeEligibilityURL = (baseUrl: any, walletAddress: any, badgeContract: any) =>
  `${baseUrl}/check?badge=${badgeContract}&recipient=${walletAddress}`
const claimBadgeURL = (baseUrl: any, walletAddress: any, badgeContract: any) =>
  `${baseUrl}/claim?badge=${badgeContract}&recipient=${walletAddress}`

const EAS_GRAPHQL_URL = 'https://scroll-sepolia.easscan.org/graphql'
const BADGE_SCHEMA = '0xa35b5470ebb301aa5d309a8ee6ea258cad680ea112c86e456d5f2254448afc74'

const SCROLL_SEPOLIA_EAS_ADDRESS = '0xaEF4103A04090071165F78D45D83A0C0782c2B2a'
const SCROLL_SEPOLIA_BADGE_SCHEMA = '0xa35b5470ebb301aa5d309a8ee6ea258cad680ea112c86e456d5f2254448afc74'
const PROFILE_REGISTRY_ADDRESS = '0xDc64a140Aa3E981100a9becA4E685f962f0cF6C9'

const initializeInstance = async (provider: any) => {
  const signer = await provider.getSigner(0)
  const profileRegistryContract = new ethers.Contract(PROFILE_REGISTRY_ADDRESS, ProfileRegistryABI, signer)
  const unsignedProfileRegistryContract = new ethers.Contract(PROFILE_REGISTRY_ADDRESS, ProfileRegistryABI, provider)
  return { profileRegistryContract, unsignedProfileRegistryContract }
}

const initializePublicInstance = async (provider: ethers.Signer | ethers.providers.Provider | undefined) => {
  const unsignedProfileRegistryContract = new ethers.Contract(PROFILE_REGISTRY_ADDRESS, ProfileRegistryABI, provider)
  return unsignedProfileRegistryContract
}

const queryUserBadges = async (userAddress: any) => {
  const query = `
      query Attestation {
        attestations(
          where: {
            schemaId: { equals: "${BADGE_SCHEMA}" },
            recipient: { equals: "${userAddress}" },
            revoked: { equals: false }
          }
        ) {
          attester
          data
          id
          time
          txid
        }
      }
    `

  try {
    const response = await fetch(EAS_GRAPHQL_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query }),
    })

    const {
      data: { attestations },
    } = await response.json()

    return attestations
  } catch (error) {
    throw new Error('Failed to query user badges:')
    // console.log("Failed to query user badges:", error)
    // return []
  }
}

const queryBadgeDetailById = async (badgeId: any) => {
  const query = `
      query Attestation {
        attestations(
          where: {
            schemaId: { equals: "${BADGE_SCHEMA}" },
            id: { equals: "${badgeId}" },
            revoked: { equals: false }
          }
        ) {
          data
          time
          recipient
        }
      }
    `

  try {
    const response = await fetch(EAS_GRAPHQL_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ query }),
    })

    const {
      data: { attestations },
    } = await response.json()

    return attestations
  } catch (error) {
    console.log(`Failed to query badge: ${badgeId}:`, error)
    return []
  }
}

const getBadgeMetadata = async (
  provider: ethers.Signer | ethers.providers.Provider | undefined,
  badgeContractAddress: string,
  badgeUID = ethers.utils.formatBytes32String('0x0')
) => {
  try {
    const contract = new ethers.Contract(badgeContractAddress, BadgeABI, provider)
    const badgeMetadataURI = await contract.badgeTokenURI(badgeUID)
    const badgeImageURI = badgeMetadataURI.replace(/^ipfs:\/\/(.*)/, 'https://ipfs.io/ipfs/$1')
    const metadata = await scrollRequest(badgeImageURI)
    return metadata
  } catch (error) {
    console.log('Failed to get badge image URI:', error)
    return ''
  }
}

const checkIfProfileMinted = async (registryInstance: any, userAddress: any) => {
  try {
    const profileAddress = await registryInstance!.getProfile(userAddress)
    const minted = await registryInstance!.isProfileMinted(profileAddress)
    return { profileAddress, minted }
  } catch (error) {
    console.log('Failed to check if profile minted:', error)
    return { profileAddress: null, minted: null }
  }
}

// work with publicProvider
const fillBadgeDetailWithPayload = async (provider: any, attestation: { data: any; id: any }) => {
  const { data, id } = attestation
  try {
    const [badgeContract] = decodeBadgePayload(data)
    const badgeMetadata = await getBadgeMetadata(provider, badgeContract, id)
    return {
      ...attestation,
      ...badgeMetadata,
      badgeContract,
    }
  } catch (error) {
    console.error('Failed to decode badge payload:', error)
  }
}

const queryUserBadgesWrapped = async (provider: any, userAddress: any) => {
  try {
    const attestations = await queryUserBadges(userAddress)
    const formattedBadgesPromises = attestations.map((attestation: any) => {
      return fillBadgeDetailWithPayload(provider, attestation)
    })
    const formattedBadges = await Promise.all(formattedBadgesPromises)
    return formattedBadges
  } catch (error) {
    throw new Error('Failed to query user badges')
    // console.log("Failed to query user badges:", error)
    // return []
  }
}

const queryCanvasUsername = async (
  provider: ethers.Signer | ethers.providers.Provider | undefined,
  profileAddress: string
) => {
  try {
    const profileContract = new ethers.Contract(profileAddress, ProfileABI, provider)
    const name = await profileContract.username()

    return { profileContract, name }
  } catch (error) {
    console.log(error, 'queryCanvasUsername')
    throw new Error('Failed to fetch username')
    // return { profileContract: null, name: null }
  }
}

const getOrderedAttachedBadges = async (profileContract: any) => {
  try {
    const badgesProxy = await profileContract!.getAttachedBadges()
    const attachedBadges = Array.from(badgesProxy)
    const badgeOrder = await getBadgeOrder(profileContract)

    const orderedAttachedBadges = badgeOrder
      .map((order, index) => [Number(order), attachedBadges[index]])
      .sort((a: any, b: any) => a[0] - b[0])
      .map((item) => item[1])

    // console.log(attachedBadges, "badges")
    // console.log(badgeOrder, "badgeOrder")
    // console.log(orderedAttachedBadges, "orderedAttachedBadges")

    return { orderedAttachedBadges, attachedBadges, badgeOrder }
  } catch (error) {
    throw new Error('Failed to query attached badges')
    // console.error("Failed to query attached badges:", error)
    // return { orderedAttachedBadges: [], badgeOrder: [] }
  }
}

const getBadgeOrder = async (profileContract: { getBadgeOrder: () => any }) => {
  try {
    const badgeOrder = await profileContract.getBadgeOrder()
    const badgeOrderArray = Array.from(badgeOrder)
    // console.log("badgeOrder", badgeOrderArray)
    return badgeOrderArray
  } catch (error) {
    console.error('Failed to query attached badges:', error)
    return []
  }
}

const fetchCanvasDetail = async (privider: any, othersAddress: any, profileAddress: any) => {
  const { profileContract, name } = await queryCanvasUsername(privider, profileAddress)
  const userBadges = await queryUserBadgesWrapped(privider, othersAddress)
  const { orderedAttachedBadges, attachedBadges, badgeOrder } = await getOrderedAttachedBadges(profileContract)
  return { name, profileContract, userBadges, attachedBadges, orderedAttachedBadges, badgeOrder }
}

// no use
const attachBadges = async (profileContract: any, badgeAddresses: any) => {
  try {
    const tx = await profileContract!['attach(bytes32[])'](badgeAddresses)
    const txReceipt = await tx.wait()
    if (txReceipt.status === 1) {
      return true
    } else {
      return 'due to any operation that can cause the transaction or top-level call to revert'
    }
  } catch (error) {
    console.log('Badges attached error!', error, badgeAddresses)
    return false
  }
}

// no use
const detachBadges = async (profileContract: any, badgeAddresses: any) => {
  try {
    const tx = await profileContract!.detach(badgeAddresses)
    const txReceipt = await tx.wait()
    if (txReceipt.status === 1) {
      return true
    } else {
      return 'due to any operation that can cause the transaction or top-level call to revert'
    }
  } catch (error) {
    console.log('Badge detached error!', error)
    return false
  }
}

const checkBadgeEligibility = async (provider: any, walletAddress: any, badge: any) => {
  try {
    // originsNFT
    if (badge.validator) {
      const eligibility = await badge.validator(provider, walletAddress)
      return eligibility
    }

    // scroll native
    if (badge.native) {
      return true
    }
    // third-party badge
    if (badge.attesterProxy) {
      const data = await scrollRequest(checkBadgeEligibilityURL(badge.baseUrl, walletAddress, badge.badgeContract))
      console.log('checkBadgeEligibility', data)
      return data
    }
    return false
  } catch (error) {
    console.log('check badge eligibility', error)
    return false
  } finally {
    // eslint-disable-next-line no-unsafe-finally
    // return false
  }
}

const mintThirdBadge = async (
  signer: { sendTransaction: (arg0: any) => any },
  walletAddress: any,
  badgeAddress: any,
  attesterProxyAddress: any,
  claimBaseUrl: any
) => {
  const { tx: unsignedTx } = await scrollRequest(claimBadgeURL(claimBaseUrl, walletAddress, badgeAddress))
  console.log(unsignedTx, 'unsignedTx')
  checkDelegatedAttestation(unsignedTx, attesterProxyAddress)
  const tx = await signer.sendTransaction(unsignedTx)
  const txReceipt = await tx.wait()
  if (txReceipt.status === 1) {
    return txReceipt.logs[0].data
  } else {
    throw new Error('due to any operation that can cause the transaction or top-level call to revert')
  }
}

const mintOriginNFTBadge = async (
  signer: ethers.Signer | ethers.providers.Provider | undefined,
  walletCurrentAddress: any,
  badgeAddress: any,
  nftAddress: any[],
  nftAbi: ethers.ContractInterface
) => {
  const nftContract = new ethers.Contract(nftAddress[0], nftAbi, signer)
  const nftV2Contract = new ethers.Contract(nftAddress[1], nftAbi, signer)
  let tokenId, nftVersion

  try {
    tokenId = await nftContract.tokenOfOwnerByIndex(walletCurrentAddress, 0)
    nftVersion = 0
  } catch (error) {
    tokenId = await nftV2Contract.tokenOfOwnerByIndex(walletCurrentAddress, 0)
    nftVersion = 1
  }

  const abiCoder = new ethers.utils.AbiCoder()
  const originsBadgePayload = abiCoder.encode(['address', 'uint256'], [nftAddress[nftVersion], tokenId])
  const badgePayload = abiCoder.encode(['address', 'bytes'], [badgeAddress, originsBadgePayload])
  const easContract = new ethers.Contract(SCROLL_SEPOLIA_EAS_ADDRESS, AttestProxyABI, signer)
  const attestParams = {
    schema: SCROLL_SEPOLIA_BADGE_SCHEMA,
    data: {
      recipient: walletCurrentAddress,
      expirationTime: 0,
      revocable: false,
      refUID: '0x0000000000000000000000000000000000000000000000000000000000000000',
      data: badgePayload,
      value: 0,
    },
  }
  const tx = await easContract.attest(attestParams)
  const txReceipt = await tx.wait()
  if (txReceipt.status === 1) {
    return txReceipt.logs[0].data
  } else {
    throw new Error('due to any operation that can cause the transaction or top-level call to revert')
  }
}

const mintPermissionlessBadge = async (
  signer: ethers.Signer | ethers.providers.Provider | undefined,
  walletCurrentAddress: any,
  badgeAddress: any
) => {
  const abiCoder = new ethers.utils.AbiCoder()
  const badgePayload = abiCoder.encode(['address', 'bytes'], [badgeAddress, '0x'])
  const easContract = new ethers.Contract(SCROLL_SEPOLIA_EAS_ADDRESS, AttestProxyABI, signer)
  const attestParams = {
    schema: SCROLL_SEPOLIA_BADGE_SCHEMA,
    data: {
      recipient: walletCurrentAddress,
      expirationTime: 0,
      revocable: false,
      refUID: '0x0000000000000000000000000000000000000000000000000000000000000000',
      data: badgePayload,
      value: 0,
    },
  }

  const tx = await easContract.attest(attestParams)
  const txReceipt = await tx.wait()
  if (txReceipt.status === 1) {
    return txReceipt.logs[0].data
  } else {
    throw new Error('due to any operation that can cause the transaction or top-level call to revert')
  }
}

const mintBadge = async (
  provider: any,
  walletCurrentAddress: any,
  badge: { badgeContract: any; nftAddress: any; nftAbi: any; attesterProxy: any; baseUrl: any }
) => {
  try {
    const { badgeContract, nftAddress, nftAbi, attesterProxy, baseUrl } = badge
    const signer = await provider!.getSigner(0)

    // Origins NFT Badge
    if (nftAddress) {
      return await mintOriginNFTBadge(signer, walletCurrentAddress, badgeContract, nftAddress, nftAbi)
    }
    // Third Party Badge
    if (attesterProxy) {
      return await mintThirdBadge(signer, walletCurrentAddress, badgeContract, attesterProxy, baseUrl)
    }

    return await mintPermissionlessBadge(signer, walletCurrentAddress, badgeContract)
  } catch (error) {
    if (error.message.includes('ACTION_REJECTED')) {
      return false
    } else {
      console.log('Failed to mint badge:', error)
      throw error
    }
  }
}

const customiseDisplay = async ({
  profileContract,
  attachBadges,
  detachBadges,
  order,
}: {
  profileContract: any
  attachBadges: any
  detachBadges: any
  order: any
}) => {
  const calls: any = []
  if (attachBadges) {
    const attachCallData = profileContract.interface.encodeFunctionData('attach(bytes32[])', [attachBadges])
    calls.push(attachCallData)
  }

  if (detachBadges) {
    const detachCallData = profileContract.interface.encodeFunctionData('detach', [detachBadges])
    calls.push(detachCallData)
  }

  if (order) {
    const reorderCallData = profileContract.interface.encodeFunctionData('reorderBadges', [order])
    calls.push(reorderCallData)
  }
  const txResponse = await profileContract.multicall(calls)
  const txReceipt = await txResponse.wait()
  // console.log(txReceipt, "txReceipt")

  if (txReceipt.status !== 1) {
    throw new Error('due to any operation that can cause the transaction or top-level call to revert')
  }
}

const reorderBadges = async (profileContract: any, badgeOrder: any) => {
  try {
    const tx = await profileContract!.reorderBadges(badgeOrder)
    const txReceipt = await tx.wait()
    if (txReceipt.status === 1) {
      return true
    } else {
      return 'due to any operation that can cause the transaction or top-level call to revert'
    }
  } catch (error) {
    console.log('Badges reordered error!', error)
    return false
  }
}

const checkIfHasBadgeByAddress = async (
  provider: ethers.Signer | ethers.providers.Provider | undefined,
  userAddress: any,
  badgeAddress: string
) => {
  try {
    const badgeContract = new ethers.Contract(badgeAddress, BadgeABI, provider)
    const hasBadge = await badgeContract.hasBadge(userAddress)
    return hasBadge
  } catch (error) {
    console.log('Failed to check if has badge by address:', error)
    return false
  }
}

const getReferrerData = async (registryInstance: { referrerData: (arg0: any) => any }, userAddress: any) => {
  const referrerData = await registryInstance.referrerData(userAddress)
  return referrerData
}

const testAsyncFunc = (value: unknown) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(value)
    }, 1000)
  })
}

// eslint-disable-next-line import/no-unused-modules
export {
  attachBadges,
  checkBadgeEligibility,
  checkIfHasBadgeByAddress,
  checkIfProfileMinted,
  customiseDisplay,
  detachBadges,
  fetchCanvasDetail,
  fillBadgeDetailWithPayload,
  getBadgeMetadata,
  getBadgeOrder,
  getOrderedAttachedBadges,
  getReferrerData,
  initializeInstance,
  initializePublicInstance,
  mintBadge,
  queryBadgeDetailById,
  queryCanvasUsername,
  queryUserBadges,
  queryUserBadgesWrapped,
  reorderBadges,
  testAsyncFunc,
}
