// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { BigNumber, ethers } from 'ethers'

import AttestProxyABI from './abis/CanvasAttestProxy.json'

export const getBadgeImgURL = (image: string) => {
  if (!image) return ''
  return image.replace(/^ipfs:\/\/(.*)/, 'https://cloudflare-ipfs.com/ipfs/$1')
}

export function generateShareTwitterURL(url: any, text: any, via = 'Scroll_ZKP') {
  return `https://twitter.com/intent/tweet?original_referer=${encodeURIComponent(
    window.location.href
  )}&url=${encodeURIComponent(url)}&text=${encodeURIComponent(text)}&via=${via}`
}

export const decodeBadgePayload = (encodedData: any) => {
  const abiCoder = new ethers.utils.AbiCoder()
  const decodedPayload = abiCoder.decode(['address', 'bytes'], encodedData)
  return decodedPayload
}

export const checkDelegatedAttestation = (tx: any, proxyAddress: string) => {
  if (!tx) {
    throw new Error('Got empty tx')
  }

  if (!tx.to || tx.to.toUpperCase() !== proxyAddress.toUpperCase()) {
    throw new Error('Unexpected contract address')
  }
  const instance = new ethers.utils.Interface(AttestProxyABI)
  const parsedTx = instance.parseTransaction(tx) as ethers.utils.TransactionDescription

  if (parsedTx.name !== 'attestByDelegation') {
    throw new Error('Unexpected function name')
  }

  if (parsedTx.args[0].length !== 5) {
    throw new Error('Unexpected number of arguments')
  }

  if (!parsedTx.value.eq(BigNumber.from(0))) {
    throw new Error('Unexpected transaction value')
  }
  return
}
